<template>
  <div class="container">
    <SimpleHeader />
    <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
      <div class="teamw">
        <van-search
          v-model="search"
          show-action
          placeholder="请输入姓名、手机号搜索"
          @search="onSearch"
        >
          <template #action>
            <van-icon name="filter-o" size="20" @click="showFilter" />
          </template>
        </van-search>
        <div class="fans">
          <div class="fans-head">业绩总览</div>
          <div class="fans-body">
            <div class="fans-infobox" v-if="info?.statistics?.length">
              <div class="fans-info" v-for="(item,index) in info?.statistics" :key="index" >
                <div class="fans-info-num">{{item.total}}</div>
                <div class="fans-info-txt">{{item.name}}</div>
              </div>
            </div>
            <div class="fans-infotxt" v-else>{{infoTxt}}</div>
          </div>
        </div>
        <div class="fans">
          <div class="fans-head">
            <span> 粉丝业绩</span>
          </div>
          <div class="fans-body">
            <van-list
              v-model:loading="loading"
              :finished="finished"
              :finished-text="finishedText"
              @load="getData"
              offset="100"
              loading-text="加载中..."
              :immediate-check="false"
            >
              <div class="attention">
                <FansItem v-for="(item,index) in list" :key="index" :item="item"></FansItem>
                <Empty :image="true" :show="!loading&&list.length==0"></Empty>
              </div>
            </van-list>
          </div>
        </div>
      </div>
    </van-pull-refresh>

    <div class="foot-btns" v-if="list.length">
      <div class="foot-btnbox">
        <button class="foot-btn" @click="toPage('/fansMore')">更多推荐</button>
      </div>
    </div>
    <!-- 筛选框 start -->
    <Filter v-model:show="filterVisible"
      @reset="resetFilter"
      @submit="submitFilter">
      <FilterCell title="粉丝类型" v-if="userTypeList.length">
        <FilterItem :list="userTypeList" bind-key="key" bind-label="txt" v-model="userType"></FilterItem>
      </FilterCell>
      <FilterCell title="用户身份" v-if="levelList.length">
        <FilterItem :list="levelList" bind-key="key" bind-label="txt" v-model="level"></FilterItem>
      </FilterCell>
      <FilterCell title="时间筛选" v-if="timeTypeList.length">
        <FilterItem :list="timeTypeList" bind-key="key" bind-label="txt" v-model="timeType"></FilterItem>
      </FilterCell>
      <FilterCell title="时间" v-if="timeTypeList.length">
        <div class="sift-box">
          <div class="sift-box--time">
            <div class="sift-item fcontentCenter" @click="timeChangeShow('startDate')">
              <div :class="{ hasValue: time.startDate != '' }">{{ time.startDate || '开始时间' }}</div>
            </div>

            <div class="sift-item fcontentCenter" @click="timeChangeShow('endDate')">
              <div :class="{ hasValue: time.endDate != '' }">{{ time.endDate || '结束时间' }}</div>
            </div>
          </div>
        </div>
      </FilterCell>
      <FilterCell title="排序" v-if="sortTypeList.length">
       <FilterItem :list="sortTypeList" bind-key="key" bind-label="txt" v-model="sortType"></FilterItem>
      </FilterCell>
    </Filter>
    <!-- 筛选框 end -->
    <div class="popBox" v-show="time.timeWhen">
      <van-datetime-picker
                v-model="show_time"
                class="popBottom"
                type="date"
                title="选择时间"
                @confirm="onConfirmTime"
                @cancel="onCancelTime"
              />
    </div>
  </div>
</template>
<script>
import { reactive, onMounted, inject, toRefs,watch  } from 'vue'
import { useRoute } from 'vue-router'
import useTime from '@/utils/time'
import timeTypeList from '@/utils/timeType'
import { List, PullRefresh, Tab, Tabs } from 'vant'
import SimpleHeader from '@/components/SimpleHeader'
import utils from '@/utils'
import Filter from '@/components/Filter'
import FilterCell from '@/components/FilterCell'
import FilterItem from '@/components/FilterItem'
import FansItem from './components/FansItem.vue'
import {getFansOptions,getFansData,getFansStatistics } from '@/api/fans'
import to from 'await-to-js';
import usePage from '@/hooks/usePage'
export default {
  name: 'fans',
  components: {
    [PullRefresh.name]: PullRefresh,
    SimpleHeader,
    Filter,
    FilterCell,
    FilterItem,
    FansItem
  },
  setup() {
    const _appLoadFanish = inject('appLoadFanish');
    const { navigateTo } = usePage()
    const {
      time,
      show_time,
      onConfirmTime,
      onCancelTime,
      timeChangeShow,
      timePickerShow,
      onConfirm,
      onCancel
    } = useTime()
    let state = reactive({
      search:'',
      timeTypeList:[],
      hasMore:true,
      page:1,
      size:20,
      list: [],
      error: false,
      loading: false,
      finished: false,
      finishedText:'',
      refreshing: false,
      filterVisible:false,
      levelList:[],
      userType:1,
      userTypeList:[],
      level:0,
      sortTypeList:[],
      sortType:0,
      timeType:'',
      info:{},
      infoTxt:'粉丝数据更新中，请稍后再试'
    })

    const getOptions = async () => {
      let [error,result] = await to(getFansOptions({from:''}))
      if(error) return;
      let {code, res} = result;
      if (code == 0) {
        state.levelList = res.level
        state.userTypeList = res.userType
        state.sortTypeList = res.sortOption
        state.timeTypeList = res.timeOption
        state.timeType = state.timeTypeList.length?'curMonth':''
      }
    }

    const getStatistics = async () => {
      let [error,result] = await to(getFansStatistics())
      if(error) {
        state.infoTxt = error.msg
        return
      };
      let {code, res, msg} = result;
      if (code == 0) {
        state.info = res.info
      }
    }

    const getData = async () => {
      if (!state.loading) {
        state.loading = true;
      }
      if (state.refreshing) {
          state.list = [];
          state.refreshing = false;
      }

      let params = {
        fromUid:0,
        level: state.level,
        sort: state.sortType,
        start: time.startDate,
        end: time.endDate,
        search: state.search,
        page: state.page,
        userType:state.userType,
        type:''
      }
      let [error,result] = await to(getFansData(params))
      _appLoadFanish()
      state.loading = false;
      if(error) {
        state.finished = true;
        state.finishedText = ''
        return
      };
      let {code, res} = result;
      if (code == 0) {
        state.list = state.page === 1 ? res.list : state.list.concat(res.list)
        state.finishedText = state.page === 1 && state.list.length == 0 ? '' : `当前共${state.list.length}条数据，已全部加载完`
        state.hasMore = res.hasMore
        if (!state.hasMore) {
          state.finished = true;
        } else {
          state.page++
        }
      }

    };
    const onSearch = () => {
      state.page = 1
      state.list = []
      getData()
    }
    const onRefresh = () => {
      state.loading = true;
      initData()
      getData();
    };
    function initData() {
      state.page = 1
      state.list = []
      state.finished = false
    }

    watch(
      () => [state.timeType],
      () => {
        let tempTime = state.timeTypeList.find(v=>v.key == state.timeType)
        if (tempTime) {
          onConfirm({value: tempTime.key, title: tempTime.txt})
        }
      }
    )
    const showFilter = ()=>{
      state.filterVisible = true
    }
    const resetFilter = ()=>{
      // state.filterVisible = false
      state.timeType = state.timeTypeList.length ? 'curMonth' : ''
      state.userType = 1
      state.level = 0
      state.sortType = 0
    }
    const submitFilter = ()=>{
      state.page = 1
      getData()
      state.filterVisible = false
    }
    const toPage = url => {

      navigateTo(url)
    }
    onMounted(async () => {
      await getOptions()
      if(state.timeTypeList.length){
        let limitTime = utils.computedTime('curMonth')
        time.startDate = limitTime.startDate
        time.endDate = limitTime.endDate
      }
      getOptions()
      getStatistics()
      getData()
    })


    return {
      ...toRefs(state),
      time,
      show_time,
      onConfirmTime,
      onCancelTime,
      timeChangeShow,
      timePickerShow,
      onConfirm,
      onCancel,
      onRefresh,
      onSearch,
      getData,
      showFilter,
      resetFilter,
      submitFilter,
      toPage
    }
  }
}
</script>

<style lang="less" scoped>
@import '../../common/style/searchpicker';
.container {
  background: #F5F6F7;
  min-height: 100%;
  padding-bottom: 50px;
  padding-bottom: calc(50px + constant(safe-area-inset-bottom,50px))!important;
  padding-bottom: calc(50px + env(safe-area-inset-bottom,50px))!important;
  .fans{
    &-head{
      height: 50px;
      line-height: 50px;
      font-weight: bold;
      padding: 0 15px;
      // background: #fff;
    }
    &-body{
      // box-sizing: border-box;
      padding: 0 15px 15px ;
    }
    &-infobox{
      background: #DABA88;
      color: #000;
      .borderRadius(6px);
      padding: 10px;
      display: grid;
      grid-template-columns: repeat(auto-fill, 33.33%);
      grid-template-rows: repeat(2, 60px);
    }
    &-infotxt{
      background: #DABA88;
      color: #000;
      .borderRadius(6px);
      height: 180px;
      line-height: 180px;
      text-align: center;
    }
    &-info{
      text-align: center;
      &-num{
        margin-top: 15px;
      }
      &-txt{
        margin-top: 4px;
      }
    }
  }
  .sift-item{
      flex: 1;
    }
}
</style>